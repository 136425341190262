import { Component } from '@angular/core';
import {AuthService} from "./services/auth-service.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Beiträge', url: '/dashboard', icon: '', show: () => this.authService.isLoggedIn },
    { title: 'Einloggen', url: '/sign-in', icon: '' , show: () => !this.authService.isLoggedIn },
    { title: 'Ausloggen', url: '/sign-out', icon: '', show: () => this.authService.isLoggedIn}
  ];
  constructor(
    private authService: AuthService
  ) {}

}
